import WxPayBaseConfig from '@/views/payManage/appManage/components/WxPayBaseConfig.vue';
import WxNativePay from '@/views/payManage/appManage/components/WxNativePay.vue';
import WxJsApiPay from '@/views/payManage/appManage/components/WxJsApiPay.vue';
import WxMinGamePayBaseConfig from '@/views/payManage/appManage/components/WxMinGamePayBaseConfig.vue';
import AlipayBaseConfig from '@/views/payManage/appManage/components/AlipayBaseConfig.vue';
import AliWebPayBaseConfig from '@/views/payManage/appManage/components/AliWebPayBaseConfig.vue';
import DouyinUnityPayBaseConfig from '@/views/payManage/appManage/components/DouyinUnityPayBaseConfig.vue';
import Google from '@/views/payManage/appManage/components/Google.vue';
import Ios from '@/views/payManage/appManage/components/Ios.vue';
import HuaweiAndroid from '@/views/payManage/appManage/components/HuaweiAndroid.vue';
import OrdinaryDouyinGame from '@/views/payManage/appManage/components/OrdinaryDouyinGame.vue';
import Amazon from '@/views/payManage/appManage/components/Amazon.vue';
import Vivo from '@/views/payManage/appManage/components/Vivo.vue';
import QuickWorker from '@/views/payManage/appManage/components/QuickWorker.vue';
export function usePay() {
  const payTypeList = [{
    title: '海外',
    type: 'overSeaBaseConfig',
    icon: 'aircraft',
    children: [{
      title: 'Google',
      key: 'google'
    }, {
      title: 'IOS',
      key: 'apple'
    }]
  }, {
    title: '支付宝',
    type: 'aliPayBaseConfig',
    icon: 'alipay',
    children: [{
      title: 'APP支付',
      key: 'aliPayBaseConfig'
    }, {
      title: '电脑网站支付',
      key: 'aliWebPayBaseConfig'
    }]
  }, {
    title: '微信',
    type: 'wxPayBaseConfig',
    icon: 'wechat',
    children: [{
      title: 'APP支付',
      key: 'wxPayBaseConfig'
    }, {
      title: 'Native支付',
      key: 'wxnativepay'
    }, {
      title: 'JSAPI支付',
      subtitle: '小游戏ios可用',
      key: 'jsApiPayBaseConfig'
    }, {
      title: '虚拟支付',
      subtitle: '小游戏安卓可用',
      key: 'wxMinGamePayBaseConfig'
    }]
  }, {
    title: '抖音',
    type: 'douyinPayBaseConfig',
    icon: 'douyin',
    children: [{
      title: 'unity小游戏',
      key: 'douyinUnityPayBaseConfig'
    }, {
      title: '普通小游戏',
      key: 'douyinOrdinaryMiniGame'
    }]
  }, {
    title: '华为',
    type: 'huawei',
    icon: 'huawei',
    children: [{
      title: '华为',
      subtitle: 'Android',
      key: 'huawei'
    }, {
      title: '鸿蒙',
      subtitle: 'Harmony',
      key: 'harmony'
    }, {
      title: '华为海外',
      key: 'huawei_foreign'
    }, {
      title: '华为小游戏',
      key: 'huawei_mg'
    }, {
      title: '华为快游戏',
      key: 'huawei_runtime_fastgame'
    }]
  }, {
    title: '亚马逊',
    type: 'amazon',
    icon: 'amazon',
    children: [{
      title: '亚马逊',
      key: 'amazon'
    }]
  }, {
    title: 'vivo',
    type: 'vivo',
    icon: 'vivo',
    children: [{
      title: 'vivo',
      subtitle: '安卓联运',
      key: 'vivo'
    }]
  }, {
    title: '快手',
    type: 'kuaishou',
    icon: 'quickWorker',
    children: [{
      title: '小游戏',
      key: 'ksminigame'
    }]
  }]; // 支付类型列表
  const rules = {
    channelRate: [{
      required: true,
      message: '请输入渠道费率',
      trigger: 'blur'
    }],
    divide: [{
      required: true,
      message: '请输入分成比例',
      trigger: 'blur'
    }],
    appId: [{
      required: true,
      message: '请输入appId',
      trigger: 'blur'
    }],
    appKey: [{
      required: true,
      message: '请输入appKey',
      trigger: 'blur'
    }],
    zoneId: [{
      required: true,
      message: '请输入zoneId',
      trigger: 'blur'
    }],
    offerId: [{
      required: true,
      message: '请输入offerId',
      trigger: 'blur'
    }],
    mchName: [{
      required: true,
      message: '请输入商户主体',
      trigger: 'blur'
    }],
    publicKey: [{
      required: true,
      message: '请输入支付宝公钥',
      trigger: 'blur'
    }],
    hwPublishKey: [{
      required: true,
      message: '请输入公钥',
      trigger: 'blur'
    }],
    appPublicKey: [{
      required: true,
      message: '请输入应用公钥',
      trigger: 'blur'
    }],
    appPrivateKey: [{
      required: true,
      message: '请输入应用私钥',
      trigger: 'blur'
    }],
    appGateway: [{
      required: true,
      message: '请输入应用网关',
      trigger: 'blur'
    }],
    appSecret: [{
      required: true,
      message: '请输入AppSecret',
      trigger: 'blur'
    }],
    merchants: [{
      required: true,
      message: '请输入商户号',
      trigger: 'blur'
    }],
    apiKey: [{
      required: true,
      message: '请输入API Key',
      trigger: 'blur'
    }],
    apiKeyV3: [{
      required: true,
      message: '请输入API Key V3',
      trigger: 'blur'
    }],
    certSerialNo: [{
      required: true,
      message: '请输入证书序列号',
      trigger: 'blur'
    }],
    wxPriKey: [{
      required: true,
      message: '请输入wx_pri_key',
      trigger: 'blur'
    }],
    signSecret: [{
      required: true,
      message: '请输入签名密钥',
      trigger: 'blur'
    }],
    signKey: [{
      required: true,
      message: '请输入签名密钥',
      trigger: 'blur'
    }],
    rate: [{
      required: true,
      message: '请选择rate',
      trigger: 'change'
    }],
    pkg: [{
      required: true,
      message: '请输入包名',
      trigger: 'blur'
    }],
    feishuTaskId: [{
      required: true,
      message: '请输入飞书任务Id',
      trigger: 'blur'
    }],
    callbackSecret: [{
      required: true,
      message: '请输入回调密钥',
      trigger: 'blur'
    }],
    paySecret: [{
      required: true,
      message: '请输入支付密钥',
      trigger: 'blur'
    }]
  }; // 表单验证规则
  const imgUrl = {
    wxPayBaseConfig: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2Fbd228d428461749723f35d07e126a80e.png',
    aliPayBaseConfig: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2F018cad9904dddee50542e34ac9668188.png',
    jsApiPayBaseConfig: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2F018cad9904dddee50542e34ac9668188.png',
    aliWebPayBaseConfig: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2F018cad9904dddee50542e34ac9668188.png',
    harmony: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2Fcdb401f0afb2e9c38df7f36fbc4b4bf0.png',
    huawei: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2Fcdb401f0afb2e9c38df7f36fbc4b4bf0.png',
    huawei_foreign: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2Fcdb401f0afb2e9c38df7f36fbc4b4bf0.png',
    huawei_mg: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2Fcdb401f0afb2e9c38df7f36fbc4b4bf0.png',
    huawei_runtime_fastgame: 'https://dbt-aigc.oss-accelerate.aliyuncs.com/picServer%2Fcdb401f0afb2e9c38df7f36fbc4b4bf0.png'
  }; // 图片地址
  const helpUrl = {
    wxPayBaseConfig: 'https://ewe8txd9jt.feishu.cn/docx/YRLGdWX8IolaiPxsAJ1cOv2onBd',
    jsApiPayBaseConfig: 'https://ewe8txd9jt.feishu.cn/wiki/AaBzwTKDFi1wMgkjDC8cP07vnxh#IcYrdcmLuo5wbhxjDT4cPHqinje',
    aliPayBaseConfig: 'https://ewe8txd9jt.feishu.cn/docx/J2YydzNatoRNIFxq9rwczJgHngb',
    aliWebPayBaseConfig: 'https://ewe8txd9jt.feishu.cn/docx/ATaadXhzYoTwv8xQnkbczZB9n7f#AXKWdVTProlaVPxlvMPcFfosnLf',
    douyinUnityPayBaseConfig: 'https://ewe8txd9jt.feishu.cn/wiki/Jibiw69dZijHACkmB5LcbeNPnVc',
    wxMinGamePayBaseConfig: 'https://ewe8txd9jt.feishu.cn/wiki/AaBzwTKDFi1wMgkjDC8cP07vnxh#IcYrdcmLuo5wbhxjDT4cPHqinje',
    wxnativepay: 'https://ewe8txd9jt.feishu.cn/docx/P7dSdqdbeomDFCxwrMAcvxDvn9e',
    douyinOrdinaryMiniGame: 'https://ewe8txd9jt.feishu.cn/wiki/Jibiw69dZijHACkmB5LcbeNPnVc',
    huawei: 'https://ewe8txd9jt.feishu.cn/wiki/APkdwu5i6i1GzIkE6NZcwrMynuj?from=from_copylink',
    harmony: 'https://ewe8txd9jt.feishu.cn/wiki/APkdwu5i6i1GzIkE6NZcwrMynuj?from=from_copylink',
    huawei_foreign: 'https://ewe8txd9jt.feishu.cn/wiki/APkdwu5i6i1GzIkE6NZcwrMynuj?from=from_copylink',
    huawei_mg: 'https://ewe8txd9jt.feishu.cn/wiki/APkdwu5i6i1GzIkE6NZcwrMynuj?from=from_copylink',
    huawei_runtime_fastgame: 'https://ewe8txd9jt.feishu.cn/wiki/APkdwu5i6i1GzIkE6NZcwrMynuj?from=from_copylink'
  }; // 操作指引地址
  const wechatRef = ref(null); // 微信app支付ref
  const jsApiRef = ref(null); // 微信jsApi支付ref
  const wxMinGameRef = ref(null); // 微信小游戏支付ref
  const nativeRef = ref(null); // 微信native支付ref
  const douyinRef = ref(null); // 抖音支付ref
  const alipayRef = ref(null); // 支付宝app支付ref
  const alipayWebRef = ref(null); // 支付宝网站支付ref
  const gpRef = ref(null); // 谷歌支付ref
  const iosRef = ref(null); // ios支付ref
  const amazonRef = ref(null); // amazon支付ref
  const vivoRef = ref(null); // vivo支付ref
  const huaweiAndroidRef = ref(null); // 华为支付ref
  const ordinaryGameRef = ref(null); // 普通小游戏支付ref
  const quickWorkerRef = ref(null); // 普通小游戏支付ref
  const componentsObj = {
    wxPayBaseConfig: WxPayBaseConfig,
    wxnativepay: WxNativePay,
    jsApiPayBaseConfig: WxJsApiPay,
    wxMinGamePayBaseConfig: WxMinGamePayBaseConfig,
    aliPayBaseConfig: AlipayBaseConfig,
    aliWebPayBaseConfig: AliWebPayBaseConfig,
    douyinUnityPayBaseConfig: DouyinUnityPayBaseConfig,
    google: Google,
    apple: Ios,
    amazon: Amazon,
    vivo: Vivo,
    huawei: HuaweiAndroid,
    harmony: HuaweiAndroid,
    huawei_foreign: HuaweiAndroid,
    huawei_mg: HuaweiAndroid,
    huawei_runtime_fastgame: HuaweiAndroid,
    douyinOrdinaryMiniGame: OrdinaryDouyinGame,
    ksminigame: QuickWorker
  };
  const refObj = {
    wxPayBaseConfig: wechatRef,
    wxnativepay: nativeRef,
    jsApiPayBaseConfig: jsApiRef,
    wxMinGamePayBaseConfig: wxMinGameRef,
    aliPayBaseConfig: alipayRef,
    aliWebPayBaseConfig: alipayWebRef,
    douyinUnityPayBaseConfig: douyinRef,
    google: gpRef,
    apple: iosRef,
    amazon: amazonRef,
    vivo: vivoRef,
    huawei: huaweiAndroidRef,
    harmony: huaweiAndroidRef,
    huawei_foreign: huaweiAndroidRef,
    huawei_mg: huaweiAndroidRef,
    huawei_runtime_fastgame: huaweiAndroidRef,
    douyinOrdinaryMiniGame: ordinaryGameRef,
    ksminigame: quickWorkerRef
  };
  return {
    payTypeList,
    rules,
    imgUrl,
    helpUrl,
    wechatRef,
    jsApiRef,
    wxMinGameRef,
    nativeRef,
    douyinRef,
    alipayRef,
    alipayWebRef,
    huaweiAndroidRef,
    ordinaryGameRef,
    gpRef,
    iosRef,
    amazonRef,
    vivoRef,
    quickWorkerRef,
    componentsObj,
    refObj
  };
}